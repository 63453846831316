import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height", "viewBox"]
const _hoisted_2 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.sizeValue,
    height: _ctx.sizeValue,
    viewBox: _ctx.viewboxValue,
    style: _normalizeStyle(_ctx.styles),
    class: "inline align-middle"
  }, [
    _createElementVNode("path", { d: _ctx.path }, null, 8, _hoisted_2)
  ], 12, _hoisted_1))
}