import { initializeApp } from "firebase/app"
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { getAuth, connectAuthEmulator, onAuthStateChanged } from "firebase/auth"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions"
import { getStorage, connectStorageEmulator } from "firebase/storage"
import store from "./store"
import { User } from "./classes/User"

const app = initializeApp({
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID
})

const firestore = getFirestore(app)
const auth = getAuth(app)
const functions = getFunctions(app, "europe-west3")
const storage = getStorage(app)

if (location.hostname == "localhost" && process.env.USE_EMULATOR == "true") {
    connectFirestoreEmulator(firestore, "localhost", 8081)
    connectFunctionsEmulator(functions, "localhost", 5001)
    connectAuthEmulator(auth, "http://localhost:9099")
    connectStorageEmulator(storage, "localhost", 9199)
}

onAuthStateChanged(auth, async (user) => {
    store.state.auth!.isAuthenticating = true
    store.state.auth!.isSignedIn = user != null
    if (user != null) {
        const userData = await store.dispatch("attachUser", user.uid) as User
        switch (userData.company) {
            case "business-luchs":
                store.state.app!.theme = "business-luchs"
                break
            case "florian-thoms":
                store.state.app!.theme = "florian-thoms"
                break
            default:
                store.state.app!.theme = "business-luchs"
                break
        }
    } else {
        await store.dispatch("detachUser")
    }
    store.state.auth!.isAuthenticating = false
})

export {
    firestore,
    auth,
    functions,
    storage
}