
import { computed, defineComponent } from "vue"

export default defineComponent({
    props: {
        type: String,
        path: { type: String, required: true },
        size: { type: [String, Number], default: 24 },
        viewbox: String,
        flip: {
            type: String,
            default: "none",
            validator: (value: string) => ["horizontal", "vertical", "both", "none"].includes(value)
        },
        rotate: { type: Number, default: 0 }
    },
    setup(props: Record<string, unknown>) {
        const styles = computed(() => {
            return {
                "--sx": ["both", "horizontal"].includes(props.flip as string) ? "-1" : "1",
                "--sy": ["both", "vertical"].includes(props.flip as string) ? "-1" : "1",
                "--r": props.rotate + "deg"
            }
        })
        const sizeValue = computed(() => {
            return props.size || 24
        })
        const viewboxValue = computed(() => {
            return props.viewbox || "0 0 24 24"
        })
        return {
            styles,
            sizeValue,
            viewboxValue
        }
    }
})
