
import { auth } from "@/firebase"
import { signOut } from "firebase/auth"
import { defineComponent } from "vue"
import IconItem from "@/components/theme/icon-item.vue"
import AvatarItem from "@/components/theme/avatar-item.vue"
import { mdiLogoutVariant } from "@mdi/js"
import { useStore } from "vuex"
import { computed } from "@vue/reactivity"
import { useRouter } from "vue-router"
import LogoItem from "../theme/logo-item.vue"

export default defineComponent({
    setup() {
        const router = useRouter()
        const store = useStore()

        const isLoading = computed(() => {
            return store.state.isLoading
        })

        const userSUB = computed(() => {
            if (store.state.auth.user) {
                return store.state.auth.user.sub
            }
            return null
        })

        const logout = async () => {
            await signOut(auth)
            router.push("/login").catch()
        }
        const openRoute = (url: string) => {
            router.push(url).catch()
        }

        const isSignedIn = computed(() => {
            return store.state.auth.isSignedIn
        })

        const showProfilePicture = computed(() => {
            return store.state.app.showProfilePicture
        })

        return {
            isLoading,
            logout,
            openRoute,
            userSUB,
            isSignedIn,
            showProfilePicture,
            mdiLogoutVariant
        }
    },
    components: {
        IconItem,
        AvatarItem,
        LogoItem
    }
})
