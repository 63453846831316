import { createStore } from "vuex"
import auth, { AuthState } from "./modules/auth"
import app, { AppState } from "./modules/app"

export interface StoreState {
    auth?: AuthState
    app?: AppState

    isLoading: boolean
}

export default createStore<StoreState>({
    state: {
        isLoading: false
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth,
        app
    }
})
