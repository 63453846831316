import { createApp } from "vue"
import App from "./app.vue"
import router from "./router"
import store from "./store"

import "./css/index.css"
import "./firebase"

if (process.env.VUE_APP_ENABLE_DEBUG_FEATURES == "true") {
    document.addEventListener("keydown", (event) => {
        switch (event.code) {
            case "F4":
                if (store.state.app!.theme == "business-luchs") {
                    store.state.app!.theme = "florian-thoms"
                } else {
                    store.state.app!.theme = "business-luchs"
                }
                break
            default:
                break
        }
    }, false)
}

createApp(App).use(store).use(router).mount("#app")
