import store from "@/store"
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: () => import(/* webpackChunkName: "home" */ "../views/home-view.vue")
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import(/* webpackChunkName: "profile" */ "../views/profile-view.vue")
    },
    {
        path: "/register",
        name: "register",
        component: () => import(/* webpackChunkName: "register" */ "../views/register-view.vue")
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/login-view.vue")
    },
    {
        path: "/login/:clientID",
        name: "login_flow",
        component: () => import(/* webpackChunkName: "login" */ "../views/login-view.vue")
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { top: 0, left: 0 }
    }
})
router.beforeEach(() => {
    store.state.isLoading = false
})

export default router
