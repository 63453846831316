
import { defineComponent, ref, onMounted, watch } from "vue"
import { storage } from "@/firebase"
import { getDownloadURL, ref as storageRef } from "firebase/storage"
import IconItem from "@/components/theme/icon-item.vue"
import { mdiAccountOutline } from "@mdi/js"
import { computed } from "@vue/reactivity"

export default defineComponent({
    props: {
        src: {
            type: String,
            required: false
        },
        sub: {
            type: String,
            required: false
        }
    },
    setup(props) {
        let firebaseSrc = ref<string | null>(null)

        const displaySrc = computed(() => {
            if (props.src) {
                return props.src
            }
            return firebaseSrc.value
        })

        const downloadProfilePicture = async () => {
            if (props.sub) {
                const _ref = storageRef(storage, `profile/${props.sub}/picture.jpeg`)
                const url = await getDownloadURL(_ref)
                firebaseSrc.value = url
            }
        }

        watch(() => props.sub, () => {
            downloadProfilePicture()
        })

        onMounted(() => {
            downloadProfilePicture()
        })

        return {
            displaySrc,
            mdiAccountOutline
        }
    },
    components: {
        IconItem
    }
})
