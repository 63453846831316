import { firestore } from "@/firebase"
import { collection, doc, getDoc, updateDoc } from "firebase/firestore"

export class User {
    private _sub: string
    gender: string = ""
    firstName: string = ""
    lastName: string = ""
    email: string = ""
    phone: string | null = null
    company: string | null = null
    lastLogin: Date | null = null
    created: Date | null = null

    constructor(sub: string) {
        this._sub = sub
    }

    async get(): Promise<boolean> {
        const doc = await getDoc(this._ref)
        if (!doc.exists) {
            return false
        }
        this.fromData(doc.data()!)
        return true
    }

    async save(): Promise<void> {
        updateDoc(this._ref, this.toData())
    }

    fromData(data: Record<string, unknown>) {
        this.gender = data.gender as string
        this.firstName = data.firstName as string
        this.lastName = data.lastName as string
        this.email = data.email as string
        if (data.company) {
            this.company = data.company as string
        }
        if (data.phone) {
            this.phone = data.phone as string
        }
    }

    toData() {
        const data = {
            gender: this.gender,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            company: this.company,
            phone: this.phone
        }
        return data
    }

    private get _ref() {
        return doc(collection(firestore, "user"), this._sub)
    }

    get sub(): string {
        return this._sub
    }
}