import { User } from "@/classes/User"
import { firestore } from "@/firebase"
import { onSnapshot, doc, collection, Unsubscribe } from "firebase/firestore"
import { Module } from "vuex"

export interface AuthState {
    isSignedIn: boolean
    isAuthenticating: boolean
    user: User | null,
    firestoreListener: {
        user: Unsubscribe | null
    }
}

const module: Module<AuthState, unknown> = {
    state: {
        isSignedIn: false,
        isAuthenticating: true,
        user: null,
        firestoreListener: {
            user: null
        }
    },
    actions: {
        attachUser({ state }, userID: string) {
            return new Promise<User>((resolve, reject) => {
                if (state.firestoreListener.user && state.user) {
                    resolve(state.user)
                    return
                }
                const userRef = doc(collection(firestore, "user"), userID)
                state.firestoreListener.user = onSnapshot(userRef, (snapshot) => {
                    if (snapshot.exists()) {
                        const user = new User(snapshot.id)
                        user.fromData(snapshot.data())
                        state.user = user
                        resolve(state.user)
                    } else {
                        reject(new Error("user not found"))
                    }
                }, (error) => {
                    reject(error)
                })
            })
        },
        detachUser({ state }) {
            if (state.firestoreListener.user) {
                state.firestoreListener.user()
                state.firestoreListener.user = null
            }
        }
    }
}
export default module