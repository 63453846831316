
import { defineComponent, computed, watch } from "vue"
import AppNavigation from "@/components/app/app-navigation.vue"
import { useStore } from "vuex"
import SnackbarItem from "./components/theme/snackbar-item.vue"
import { useRoute } from "vue-router"

export default defineComponent({
    setup() {
        const store = useStore()
        const route = useRoute()
        const isLoading = computed(() => {
            return store.state.auth.isAuthenticating || store.state.isLoading
        })
        const isAuthenticating = computed(() => {
            return store.state.auth.isAuthenticating
        })
        const appClassList = computed(() => {
            const list = ["bg-white"]
            switch(store.state.app.theme) {
                case "business-luchs":
                    list.push("theme-business-luchs")
                default:
                    list.push("theme-florian-thoms")
                    break
            }
            return list
        })
        
        const showAppNavigation = computed(() => {
            if (route.name == "login" || route.name == "register") {
                return false
            }
            return true
        })

        watch(() => store.state.app.theme, () => {
            const switchFavicon = (favicon: string) => {
                const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
                link.href = favicon
            }
            switch(store.state.app.theme) {
                case "business-luchs":
                    document.title = "Business Luchs - Login"
                    switchFavicon("/favicon_bl.png")
                    break
                default:
                    document.title = "Florian Thoms - Login"
                    switchFavicon("/favicon.png")
                    break
            }
        }, { immediate: true })

        const showSnackbar = computed({
            get(): boolean {
                return store.state.app.snackbar.visible
            },
            set(newVal: boolean) {
                store.state.app.snackbar.visible = newVal
            }
        })
        const snackbarMessage = computed(() => {
            return store.state.app.snackbar.message
        })
        const snackbarColor = computed(() => {
            return store.state.app.snackbar.color
        })

        return {
            isLoading,
            isAuthenticating,
            appClassList,
            showAppNavigation,
            showSnackbar,
            snackbarMessage,
            snackbarColor
        }
    },
    components: {
        AppNavigation,
        SnackbarItem
    }
})
